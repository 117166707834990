import client from "@/api/client/axios";
import type { ResponseConfig } from "@/api/client/axios";
import type { GetCategoriesQueryResponse } from "../../types/GetCategories";

/**
     * @link /api/purchase-tenders/pub/v1/categories
     */
export async function getCategories (options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<GetCategoriesQueryResponse>["data"]> {
    const { data: resData } = await client<GetCategoriesQueryResponse>({
        method: "get",
        url: `/api/purchase-tenders/pub/v1/categories`,
        ...options
    });
    
    return resData;
};