import type { QueryKey, UseQueryResult, UseQueryOptions, QueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import client from "@/api/client/axios";
import type { GetStatusesForAdminQueryResponse, GetStatusesForAdmin400, GetStatusesForAdmin401, GetStatusesForAdmin403, GetStatusesForAdmin422, GetStatusesForAdmin429, GetStatusesForAdmin500 } from "../types/GetStatusesForAdmin";

export const getStatusesForAdminQueryKey = () =>     [{url:`/api/credit-flow-backend/private/v1/statuses/view_for-admin`}] as const;
      export function getStatusesForAdminQueryOptions <TData = GetStatusesForAdminQueryResponse, TError = GetStatusesForAdmin400 | GetStatusesForAdmin401 | GetStatusesForAdmin403 | GetStatusesForAdmin422 | GetStatusesForAdmin429 | GetStatusesForAdmin500>(options: Partial<Parameters<typeof client>[0]> = {}): UseQueryOptions<TData, TError> {
        const queryKey = getStatusesForAdminQueryKey();

        return {
          queryKey,
          queryFn: () => {
            
            return client<TData, TError>({
              method: "get",
              url: `/api/credit-flow-backend/private/v1/statuses/view_for-admin`,
              
              
              ...options,
            }).then(res => res.data);
          },
        };
      };
      
/**
 * @link /api/credit-flow-backend/private/v1/statuses/view_for-admin
 */

export function useGetStatusesForAdmin <TData = GetStatusesForAdminQueryResponse, TError = GetStatusesForAdmin400 | GetStatusesForAdmin401 | GetStatusesForAdmin403 | GetStatusesForAdmin422 | GetStatusesForAdmin429 | GetStatusesForAdmin500>(options: {
          query?: UseQueryOptions<TData, TError>,
          client?: Partial<Parameters<typeof client<TData, TError>>[0]>,
        } = {}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getStatusesForAdminQueryKey();

  const query = useQuery<TData, TError>({
    ...getStatusesForAdminQueryOptions<TData, TError>(clientOptions),
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey as QueryKey;

  return query;
};
