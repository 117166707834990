import pick from 'lodash/pick'
import type { TGetPaginationQuery } from '@/api/hooks/interfaces'
import {
  EAgroservicesApiUrl,
  EAnalyticsApiUrl,
  ECartApiUrl,
  ECatalogApiUrl,
  ECommonApiUrl,
  EFaqApiUrl,
  EHarvestSaleLotsApiUrl,
  EHarvestSaleMultiLotsApiUrl,
  EInfoportalApiUrls,
  // EJournalApiUrl,
  ELkApiUrl,
  ELogisticApiUrl,
  EPartnershipApiUrl,
} from '@/api/urls'
import { ESberIntegrationApiUrl } from '@/api/urls/ESberIntegrationApiUrl'
import { EFundingRequestWorkerName } from '@/types'
import { createRouterLink, getUrlWithQuery } from '@/utils/url'
import { getLocationQuery } from '../app/reducer'
import type { TApiUrls } from './interfaces'

let getPaginationQuery: TGetPaginationQuery
import('@/api/hooks/helpers').then(module => (getPaginationQuery = module.getPaginationQuery))

export const apiUrls: TApiUrls = {
  saleRequestDocuments: ({ requestId }) => createRouterLink(EHarvestSaleLotsApiUrl.saleRequestDocuments, { requestId }),
  multiRequestDocuments: ({ requestId }) => createRouterLink(EHarvestSaleLotsApiUrl.multiRequestDocuments, { requestId }),
  saleRequestAccreditationDocuments: ({ requestId }) =>
    getUrlWithQuery(EHarvestSaleLotsApiUrl.saleRequestAccreditationDocuments, { requestId }),
  multiRequestAccreditationDocuments: ({ requestId }) =>
    getUrlWithQuery(EHarvestSaleLotsApiUrl.multiRequestAccreditationDocuments, { requestId }),
  saleRequest: ({ id }) => `${EHarvestSaleLotsApiUrl.saleRequest}/${id}`,
  saleMultiRequest: ({ id }) => `${EHarvestSaleMultiLotsApiUrl.saleRequest}/${id}`,
  stores: () => ECommonApiUrl.stores,
  deliveryBasisVariants: () => EHarvestSaleLotsApiUrl.deliveryBasisVariants,
  elevators: ({ regionCode }) => getUrlWithQuery(ECommonApiUrl.elevators, { regionCode }),
  legalDocuments: () => ECommonApiUrl.legalDocuments,
  myChats: () => ECommonApiUrl.myChats,
  regions: () => ECommonApiUrl.regions,
  sberRegions: () => ESberIntegrationApiUrl.directoryRegions,
  accreditation: ({ id }) => `${ECommonApiUrl.accreditation}/${id}`,
  accreditationDocuments: ({ id }) => `${ECommonApiUrl.accreditation}/${id}/documents`,
  accreditationHistory: ({ id }) => `${ECommonApiUrl.accreditation}/${id}/history`,
  harvest: ({ id }) => `${ECommonApiUrl.harvest}/${id}`,
  faqCategoryQuestions: ({ specializationId }) => `${EFaqApiUrl.CATEGORIES}/${specializationId}/articles`,
  faqCategories: () => EFaqApiUrl.CATEGORIES,
  faqQuestions: (_params, state) =>
    getUrlWithQuery(EFaqApiUrl.ARTICLES, {
      ...pick(getLocationQuery(state), ['text', 'tags', 'categoryId']),
      ...getPaginationQuery?.()(state),
    }),
  faqCategory: ({ categoryId }) => `${EFaqApiUrl.CATEGORIES}/${categoryId}`,
  faqSpecialization: ({ specializationId }) => `${EFaqApiUrl.CATEGORIES}/${specializationId}`,
  faqArticle: ({ questionId }) => `${EFaqApiUrl.ARTICLES}/${questionId}`,
  faqAuthor: ({ authorId }) => `${EFaqApiUrl.AUTHORS}/${authorId}`,
  agroserviceProducts: () => EAgroservicesApiUrl.AGROSERVICE_PRODUCTS,
  indexAgriculturalProducts: ({ category: categoryId }) =>
    getUrlWithQuery(createRouterLink(ECatalogApiUrl.category, { categoryId }), { limit: 4, offset: 0 }),
  publicationsCount: () => EInfoportalApiUrls.publicationsCount,
  userOrganization: () => ELkApiUrl.organization,
  expressCreditWithRedirection: () => `${ECommonApiUrl.config}/${EFundingRequestWorkerName.VTB_LOAN}`,
  cultureIndicators: ({ cultureId, organization }) =>
    getUrlWithQuery(`${EHarvestSaleLotsApiUrl.qualityIndicators}/${cultureId}`, { organization }),
  harvestSaleQualityIndicators: ({ cultureId, organization }) =>
    getUrlWithQuery(`${EHarvestSaleLotsApiUrl.qualityIndicators}/${cultureId}`, { organization }),
  harvestSaleCultureClasses: () => getUrlWithQuery(EHarvestSaleLotsApiUrl.templates, { limit: 0, offset: 0 }),
  harvestSalePage: () => getUrlWithQuery(EHarvestSaleLotsApiUrl.templates, { limit: 3 }),
  partnershipProfiles: () => EPartnershipApiUrl.profileTypes,
  cultures: params => getUrlWithQuery(EHarvestSaleLotsApiUrl.cultures, { activeTemplatesOnly: Boolean(params?.activeTemplatesOnly) }),
  multiCart: () => ECartApiUrl.multiCart,
  cart: ({ cartId }) => `${ECartApiUrl.cart}/${cartId}`,
  paymentVariants: ({ cartId, regionCode, sumCart }) =>
    getUrlWithQuery(`${ECartApiUrl.paymentVariants}/${cartId}`, { regionCode, sumCart }),
  statusModel: () => ECommonApiUrl.statusModel,
  logistics: ({ id }) => `${ELogisticApiUrl.LOGISTIC}${id ? `/${id}` : ''}`,
  vehicleTypes: () => ELogisticApiUrl.VEHICLE_TYPES,
  agrarianLoadingMethods: () => ECommonApiUrl.loadingMethods,
  agrarianWorkingDays: () => ECommonApiUrl.workingDays,
  transactionReports: () => EAnalyticsApiUrl.transactionReports,
  agrarianWarehouses: ({ agrarianId }) => getUrlWithQuery(ECommonApiUrl.agrarianWarehouses, { agrarianId }),
  agriculturalProductCategoriesFilters: () => '',
  addOrganization: () => '',
}
