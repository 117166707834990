import client from "@/api/client/axios";
import type { ResponseConfig } from "@/api/client/axios";
import type { GetFederalDistrictsQueryResponse } from "../../types/GetFederalDistricts";

/**
     * @summary Получение федеральных округов
     * @link /api/regions/federal-districts
     */
export async function getFederalDistricts (options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<GetFederalDistrictsQueryResponse>["data"]> {
    const { data: resData } = await client<GetFederalDistrictsQueryResponse>({
        method: "get",
        url: `/api/regions/federal-districts`,
        ...options
    });
    
    return resData;
};