import client from "@/api/client/axios";
import type { ResponseConfig } from "@/api/client/axios";
import type { ViewByFundingAgentMutationRequest, ViewByFundingAgentMutationResponse } from "../../types/ViewByFundingAgent";

/**
     * @link /api/credit-flow-backend/private/v1/fundings/view_by-funding-agent
     */
export async function viewByFundingAgent (data: ViewByFundingAgentMutationRequest, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<ViewByFundingAgentMutationResponse>["data"]> {
    const { data: resData } = await client<ViewByFundingAgentMutationResponse, ViewByFundingAgentMutationRequest>({
        method: "post",
        url: `/api/credit-flow-backend/private/v1/fundings/view_by-funding-agent`,
        data,
        ...options
    });
    
    return resData;
};