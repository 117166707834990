import { requestData, requestDataWithRawResponse } from '@/api/request'
import { EHarvestSaleLotsApiUrl } from '@/api/urls'
import { DEFAULT_LIMIT_DOCUMENTS, DEFAULT_OFFSET_DOCUMENTS } from '@/services/documents/constants'
import type { IDocumentsResponse } from '@/services/documents/interfaces'
import { downloadFileFromResponse } from '@/utils/files'
import { createRouterLink, getUrlWithQuery } from '@/utils/url'

interface IUploadDocumentOptions {
  file: File
  name: string
  type: string
  id: number
  version?: string
}

interface IUpdateDocumentOptions {
  file: File
  name: string
  type: string
  id: number
  version?: string
  documentId: string
}

interface IDeleteDocumentOptions {
  id: number
  version?: string
  documentId: string
}

export interface IFetchDocumentOptions {
  id: number
  limit?: number
  offset?: number
  commonTail?: boolean
}

/**
 * Сервис работы с документами заявки на продажу зерна в outputs
 *
 * TODO: Избавиться от сервиса получится, только когда появится описание OpenAPI
 *   для POST, PUT в монолите. До этого для uploadDocument, updateDocument
 *   нет генерации для API
 */
export default class SaleRequestDocumentsService {
  private isMultiRequest: boolean

  constructor(isMultiRequest = false) {
    this.isMultiRequest = isMultiRequest
  }

  private getDocumentsUrl() {
    return this.isMultiRequest ? EHarvestSaleLotsApiUrl.multiRequestDocuments : EHarvestSaleLotsApiUrl.saleRequestDocuments
  }

  private getDocumentUrl() {
    return this.isMultiRequest ? EHarvestSaleLotsApiUrl.multiRequestDocument : EHarvestSaleLotsApiUrl.saleRequestDocument
  }

  /**
   * Загрузка документа в заявку
   * @param  {Object} options
   * @prop  {File} options.file - файл
   * @prop  {string} options.name - имя файла
   * @prop  {string} options.type - тип файла
   * @prop  {number} options.id - идентификатор заявки
   * @prop  {string} options.version - версия запроса, поле updateDate в заявке
   */
  uploadDocument = ({ file, name, type, id, version }: IUploadDocumentOptions) => {
    const formData = new FormData()
    formData.append('documentFile', file)
    formData.append('documentTitle', name)
    formData.append('documentType', type)

    return requestData({
      url: getUrlWithQuery(createRouterLink(this.getDocumentsUrl(), { requestId: id }), { version }),
      method: 'POST',
      body: formData,
      isJson: false,
    })
  }

  /**
   * Обновление документа в заявке
   * @param {Object} options
   * @prop {File} options.file - файл
   * @prop {string} options.name - имя файла
   * @prop {string} options.type - тип файла
   * @prop {number} options.id - идентификатор заявки
   * @prop {string} options.version - версия запроса, поле updateDate в заявке
   * @prop {string} options.documentId - идентификатор документа
   */
  updateDocument = ({ file, name, type, id, version, documentId }: IUpdateDocumentOptions) => {
    const formData = new FormData()
    formData.append('documentFile', file)
    formData.append('documentTitle', name)
    formData.append('documentType', type)

    return requestData({
      url: getUrlWithQuery(createRouterLink(this.getDocumentUrl(), { requestId: id, docId: documentId }), { version }),
      method: 'PUT',
      body: formData,
      isJson: false,
    })
  }

  /**
   * Удаление документа из заявки
   * @param  {Object} options
   * @prop {number} options.id - идентификатор заявки
   * @prop {string} options.version - версия запроса, поле updateDate в заявке
   * @prop {string} options.documentId - идентификатор документа
   */
  deleteDocument = ({ id, version, documentId }: IDeleteDocumentOptions) =>
    requestData({
      url: getUrlWithQuery(createRouterLink(this.getDocumentUrl(), { requestId: id, docId: documentId }), {
        version,
      }),
      method: 'DELETE',
    })

  /**
   * Скачивание документа в заявке
   * @param  {string} url - версия запроса, поле updateDate в заявке
   * @param {string} fileName - идентификатор документа
   */
  downloadDocument = (url: string, fileName: string) =>
    requestDataWithRawResponse<Blob>({ url, method: 'GET' }).then(async response => {
      await downloadFileFromResponse(response, fileName)
    })

  /**
   * Запрос документов заказа
   * @param {number} id - идентификатор заявки
   * @param {number} limit - количество документов на странице
   * @param {number} offset - смещение для пагинации
   * @param {boolean} commonTail - флаг, который добавляет документы с typeId EDocumentTypeId.COMMON в конец списка
   */
  fetchDocuments = ({
    id,
    limit = DEFAULT_LIMIT_DOCUMENTS,
    offset = DEFAULT_OFFSET_DOCUMENTS,
    commonTail = false,
  }: IFetchDocumentOptions) =>
    requestData<IDocumentsResponse>({
      url: getUrlWithQuery(createRouterLink(this.getDocumentsUrl(), { requestId: id }), { limit, offset, commonTail }),
      method: 'GET',
    })
}
