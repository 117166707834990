import type { UseMutationOptions, UseMutationResult } from "@tanstack/react-query";
import { useMutation } from "@tanstack/react-query";
import client from "@/api/client/axios";
import type { ResponseConfig } from "@/api/client/axios";
import type { UpdateFundingRequestByAdminMutationRequest, UpdateFundingRequestByAdminMutationResponse, UpdateFundingRequestByAdminQueryParams, UpdateFundingRequestByAdmin400, UpdateFundingRequestByAdmin401, UpdateFundingRequestByAdmin403, UpdateFundingRequestByAdmin422, UpdateFundingRequestByAdmin429, UpdateFundingRequestByAdmin500 } from "../types/UpdateFundingRequestByAdmin";

/**
 * @link /api/credit-flow-backend/private/v1/funding/edit_draft-fields-for-admin
 */

export function useUpdateFundingRequestByAdmin <TData = UpdateFundingRequestByAdminMutationResponse, TError = UpdateFundingRequestByAdmin400 | UpdateFundingRequestByAdmin401 | UpdateFundingRequestByAdmin403 | UpdateFundingRequestByAdmin422 | UpdateFundingRequestByAdmin429 | UpdateFundingRequestByAdmin500, TVariables = UpdateFundingRequestByAdminMutationRequest>(params: UpdateFundingRequestByAdminQueryParams, options: {
          mutation?: UseMutationOptions<ResponseConfig<TData>, TError, TVariables>,
          client?: Partial<Parameters<typeof client<TData, TError, TVariables>>[0]>,
      } = {}): UseMutationResult<ResponseConfig<TData>, TError, TVariables> {
  const { mutation: mutationOptions, client: clientOptions = {} } = options ?? {};

  return useMutation<ResponseConfig<TData>, TError, TVariables>({
    mutationFn: (data) => {
      
      return client<TData, TError, TVariables>({
        method: "put",
        url: `/api/credit-flow-backend/private/v1/funding/edit_draft-fields-for-admin`,
        data,
        params,
        
        ...clientOptions
      });
    },
    ...mutationOptions
  });
};
