import client from "@/api/client/axios";
import type { ResponseConfig } from "@/api/client/axios";
import type { GetAgrariansPublicQueryResponse } from "../../types/GetAgrariansPublic";

/**
     * @link /api/purchase-tenders/pub/v1/agrarians
     */
export async function getAgrariansPublic (options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<GetAgrariansPublicQueryResponse>["data"]> {
    const { data: resData } = await client<GetAgrariansPublicQueryResponse>({
        method: "get",
        url: `/api/purchase-tenders/pub/v1/agrarians`,
        ...options
    });
    
    return resData;
};