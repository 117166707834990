import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import type { TDropdownListFooterProps, TOnChangeDropdownItem } from '@frontend/pole-ui/lib/components/Dropdown'
import find from 'lodash/find'
import { AddingLocationButton } from '@/components/composed/modals/ChoosingLocationModal/components'
import { Dropdown } from '@/components/ui/fields'
import { transformOptions } from '@/components/ui/fields/helpers/Dropdown'
import { Dropdown as ReactHookFormDropdown } from '@/components/ui/ReactHookFormFields'
import { loadData } from '@/logic/data/actions'
import { pushGtmEventOnChangeFilterFormClick } from '@/logic/metrika/harvest/helpers'
import { getUserGtmInfo } from '@/logic/metrika/helpers'
import { getUserRegion } from '@/logic/userRegion'
import type { RootState } from '@/redux/interfaces'
import type { IStoreLocation } from '@/types'
import type { ICustomUserStore } from '@/utils/hooks/useUserStores'
import { useUserStores } from '@/utils/hooks/useUserStores'
import type { IStoreInput } from './interfaces'
import { messages } from './messages'

const mapState = (state: RootState) => ({
  userGtmInfo: getUserGtmInfo()(state),
  userRegion: getUserRegion()(state),
})

const StoreInput: React.FC<IStoreInput> = props => {
  const {
    name,
    labelId,
    label,
    shouldLoadElevators,
    onAddNewLocation,
    onStoreChange,
    placeholder,
    placeholderId,
    withSelectablePlaceholder,
    onAddButtonClick,
    dropdownProps,
    position,
    options,
    onOpen,
    context,
    control,
    customValidator,
  } = props
  const dispatch = useDispatch()
  const { formatMessage } = useIntl()
  const { userGtmInfo, userRegion } = useSelector(mapState)
  const { isLocationsLoading, locations: storeLocations } = useUserStores()

  const [chosenNewAddress, setChosenNewAddress] = useState('')

  const locations =
    withSelectablePlaceholder && (placeholderId || placeholder)
      ? [
          {
            id: 'placeholder',
            address: placeholder || formatMessage({ id: placeholderId }),
          } as IStoreLocation,
          ...storeLocations,
        ]
      : storeLocations

  const loadElevators = (storeLocation: IStoreLocation) => {
    const regionCode = storeLocation?.region?.code

    if (regionCode) {
      dispatch(loadData({ name: 'elevators', shouldLoadForcibly: true, params: { regionCode } }))
    }
  }

  const onAddNewAddress = (address: string) => {
    setChosenNewAddress(address)
  }

  useEffect(() => {
    if (chosenNewAddress) {
      const location = find(locations, { address: chosenNewAddress })

      if (location) {
        setChosenNewAddress('')
        if (onAddNewLocation) {
          onAddNewLocation(location)
          onStoreChange?.(location)
          if (shouldLoadElevators) loadElevators(location)
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chosenNewAddress, locations])

  const locationOptions = transformOptions<ICustomUserStore, string>({
    options: locations,
    valueKey: 'id',
    labelKey: 'address',
  })

  const storeChangedHandler: TOnChangeDropdownItem<string> = storeId => {
    const location = find(locations, { id: storeId }) as IStoreLocation
    pushGtmEventOnChangeFilterFormClick(userGtmInfo, userRegion.name, 'store', storeId)
    onStoreChange?.(location)

    if (shouldLoadElevators) loadElevators(location)
  }

  const DropdownListFooter = ({ onClose }: TDropdownListFooterProps) => {
    const handleClick = async () => {
      onClose()

      return onAddButtonClick?.() ?? true
    }

    return <AddingLocationButton context={context} position={position} onClick={handleClick} onAddAddress={onAddNewAddress} />
  }

  if (control) {
    return (
      <ReactHookFormDropdown
        name={name}
        options={options || locationOptions}
        label={labelId}
        DropdownListFooter={DropdownListFooter}
        isDisabled={isLocationsLoading}
        control={control}
        {...dropdownProps}
        onOpen={onOpen}
        customValidator={customValidator}
      />
    )
  }

  return (
    <Dropdown
      name={name}
      options={options || locationOptions}
      labelId={labelId}
      placeholder={!labelId && !label ? messages.label : label}
      DropdownListFooter={DropdownListFooter}
      isDisabled={isLocationsLoading}
      onChange={storeChangedHandler}
      {...dropdownProps}
      onOpen={onOpen}
    />
  )
}

export default StoreInput
