import type { QueryKey, UseQueryResult, UseQueryOptions, QueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import client from "@/api/client/inputs-catalog";
import type { GetProductSupplierCountQueryResponse, GetProductSupplierCountQueryParams, GetProductSupplierCount400, GetProductSupplierCount404, GetProductSupplierCount500 } from "../types/GetProductSupplierCount";

export const getProductSupplierCountQueryKey = (params: GetProductSupplierCountQueryParams) =>     [{url:`/v1/product/view_supplier-count`},...(params ? [params] : [])] as const;
      export function getProductSupplierCountQueryOptions <TData = GetProductSupplierCountQueryResponse, TError = GetProductSupplierCount400 | GetProductSupplierCount404 | GetProductSupplierCount500>(params: GetProductSupplierCountQueryParams, options: Partial<Parameters<typeof client>[0]> = {}): UseQueryOptions<TData, TError> {
        const queryKey = getProductSupplierCountQueryKey(params);

        return {
          queryKey,
          queryFn: () => {
            
            return client<TData, TError>({
              method: "get",
              url: `/v1/product/view_supplier-count`,
              params,
              
              ...options,
            }).then(res => res.data);
          },
        };
      };
      
/**
 * @link /v1/product/view_supplier-count
 */

export function useGetProductSupplierCount <TData = GetProductSupplierCountQueryResponse, TError = GetProductSupplierCount400 | GetProductSupplierCount404 | GetProductSupplierCount500>(params: GetProductSupplierCountQueryParams, options: {
          query?: UseQueryOptions<TData, TError>,
          client?: Partial<Parameters<typeof client<TData, TError>>[0]>,
        } = {}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getProductSupplierCountQueryKey(params);

  const query = useQuery<TData, TError>({
    ...getProductSupplierCountQueryOptions<TData, TError>(params, clientOptions),
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey as QueryKey;

  return query;
};
