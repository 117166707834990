import client from "@/api/client/axios";
import type { ResponseConfig } from "@/api/client/axios";
import type { GetLastOrderAccreditationQueryResponse } from "../../types/GetLastOrderAccreditation";

/**
     * @summary Запрос на получения актуальной аккредитации для организации
     * @link /api/accreditations/order/last
     */
export async function getLastOrderAccreditation (options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<GetLastOrderAccreditationQueryResponse>["data"]> {
    const { data: resData } = await client<GetLastOrderAccreditationQueryResponse>({
        method: "get",
        url: `/api/accreditations/order/last`,
        ...options
    });
    
    return resData;
};