import client from "@/api/client/axios";
import type { ResponseConfig } from "@/api/client/axios";
import type { CreateAccreditationMutationRequest, CreateAccreditationMutationResponse } from "../../types/CreateAccreditation";

/**
     * @summary Запрос на создание аккредитации
     * @link /api/accreditations
     */
export async function createAccreditation (data: CreateAccreditationMutationRequest, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<CreateAccreditationMutationResponse>["data"]> {
    const { data: resData } = await client<CreateAccreditationMutationResponse, CreateAccreditationMutationRequest>({
        method: "post",
        url: `/api/accreditations`,
        data,
        ...options
    });
    
    return resData;
};