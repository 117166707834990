import { getFederalDistricts, getFederalDistrictsQueryKey } from '@/api/kubik/monolith'
import { getAgrariansPublic, getAgrariansPublicQueryKey, getCategories, getCategoriesQueryKey } from '@/api/kubik/tender'
import type { TSsrPrefetchQueries } from '@/interfaces'

export type PageParams = 'categoryId' | 'ownerOrganizations' | 'federalDistricts' | 'regionCodes' | 'page'

export const ssrPrefetchQueriesForTenders: TSsrPrefetchQueries<PageParams> = () => {
  return [
    { queryFn: getCategories, queryKey: getCategoriesQueryKey() },
    { queryFn: getAgrariansPublic, queryKey: getAgrariansPublicQueryKey() },
    { queryFn: getFederalDistricts, queryKey: getFederalDistrictsQueryKey() },
  ]
}
