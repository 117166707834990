export enum EHarvestSaleLotsApiUrl {
  requests = '/api/requests',
  multiRequests = '/api/multirequests',
  deliveryBasisVariants = '/api/requests/delivery-basis/variants',
  qualityIndicators = '/api/requests/data/quality-indicators/culture',
  templates = '/api/requests/list/templates',
  buyers = '/api/requests/buyers',
  cultures = '/api/requests/cultures',
  proteins = '/api/requests/data/proteins',
  uploadingPriceList = '/api/requests/upload-templates',
  expansionRequest = '/api/expansion/request',
  uploadingAnalyzedPrices = '/api/price/analyzed/upload',
  cultureClasses = '/api/requests/data/culture-classes',
  accreditation = '/api/requests/salesRequests/accreditation/documents',
  saleRequest = '/api/salerequests',
  saleRequestHistory = '/api/salerequests/:requestId/history',
  saleRequestDocuments = '/api/salerequests/:requestId/documents',
  saleRequestAccreditationDocuments = '/api/requests/SaleRequest/accreditation/documents',
  saleRequestDocument = '/api/salerequests/:requestId/documents/:docId',
  multiRequestDocuments = '/api/multirequests/:requestId/documents',
  multiRequestAccreditationDocuments = '/api/requests/MultiRequest/accreditation/documents',
  multiRequestDocument = '/api/multirequests/:requestId/documents/:docId',
  saleRequestChat = '/api/salerequests/:requestId/chat',
  saleRequestNextStatus = '/api/salerequests/:requestId/status/next',
  saleRequestWithId = '/api/salerequests/:requestId',
  draftRequest = '/api/requests/:cultureId/draft',
  getDraftRequest = '/api/requests/draft',
}
